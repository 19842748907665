import { FC } from 'react';
import { Card, Row, Col, Skeleton, Alert, Spin } from 'antd';
import { useQuery, useQueryClient} from 'react-query';

import Asset from 'components/Asset/Asset';
import Box from 'components/Box/Box';
import UploadFile from 'components/UploadFile/UploadFile';
import { IAsset } from 'types/types';
import API from 'utils/API';

type AssetsProps = {
    onDragStart: (e: React.DragEvent<HTMLDivElement>, assetId: string) => void;
};

type IAssetsProcessingStatus = 'idle' | 'processing' | 'done' | 'error';

const AssetsProcessingStatus: FC = () => {
    const queryClient = useQueryClient();
    const {
        data: { status } = { status: 'idle' },
        isLoading: isLoadingStatus,
    } = useQuery<{ status: IAssetsProcessingStatus }>(
        'assetsProcessingStatus',
        API.getAssetsProcessingStatus,
        {
            refetchInterval: (data) => {
                if (data?.status === 'processing' ) {
                    return 500
                }
                return false
            },
            onSuccess(data) {
                if (data.status === 'done') {
                    queryClient.invalidateQueries('assets');
                }
            },
        }
    );

    return (
        <Spin spinning={isLoadingStatus} delay={500}>
            {status === 'processing' && (
                <Alert message="Processing assets...." type="info" showIcon closable />
            )}

            {status === 'done' && <Alert message="Assets processed" type="success" showIcon closable />}

            {status === 'error' && (
                <Alert message="Error processing assets" type="error" showIcon closable />
            )}
        </Spin>
    )

};

const Assets: FC<AssetsProps> = ({ onDragStart }: AssetsProps) => {
    const { data: assets, isLoading, isError, error } = useQuery<IAsset[]>('assets', API.getAssets);
    const { data: { status } = { status: 'idle' }, isLoading: isLoadingStatus } = useQuery<{
        status: IAssetsProcessingStatus;
    }>('assetsProcessingStatus', API.getAssetsProcessingStatus);

    const isLoadingAssets = isLoading || isLoadingStatus;
    const isProcessing = status === 'processing' || isLoadingAssets;

    if (isError) {
        return <Box>Error</Box>;
    }

    return (
        <>
            <Card style={{ overflow: 'auto', maxHeight: '100vh' }}>
                <AssetsProcessingStatus />
                <UploadFile />
                <Row gutter={8} style={{ marginTop: '1rem' }}>
                    {isProcessing && (
                        <Col className="gutter-row" span={12}>
                            <Skeleton.Image active style={{ height: '171px', width: '130px' }} />
                        </Col>
                    )}
                    {assets?.length === 0
                        ? null
                        : assets?.map((asset) => (
                            <Col key={asset.id} className="gutter-row" span={12}>
                                <Asset
                                    assetId={asset.id}
                                    previewImage={asset.previewImage}
                                    onDragStart={onDragStart}
                                />
                            </Col>
                        ))}
                </Row>
                <Box color="#6b7280" textAlign="center" style={{ marginTop: '1rem' }}>
                    {/* Drag and drop assets to the product */}
                    Sleep een afbeelding naar het product
                </Box>
            </Card>
        </>
    );
};

export default Assets;
