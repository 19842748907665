import axios from 'axios';
import { useState } from 'react';
import { Upload, Progress, Alert } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import type { RcFile, UploadProps } from 'antd/es/upload/interface';

import Box from 'components/Box/Box';
import useQueryParams from 'hooks/query-params';
import { useQueryClient } from 'react-query';

const getSignedUrl = async (sessionId: string, file: File) => {
  const {
    data: { signedUrl },
  } = await axios.post<{ signedUrl: string }>(
    `${process.env.REACT_APP_PRINT_API_URL}/uploads/signed-url`,
    {
      fileName: file.name,
      fileType: file.type,
      sessionId,
    },
  );
  return signedUrl;
};

const postNewUploadMessage = (sessionId: string, file: File) => {
  return axios.post(`${process.env.REACT_APP_PRINT_API_URL}/messages`, {
    sessionId,
    messageType: 'NEW_UPLOAD',
    message: {
      files: [
        {
          fileName: file.name,
          fileType: file.type,
        },
      ],
    },
  });
};

const UploadFile = () => {
  const queryClient = useQueryClient();
  const { sessionId } = useQueryParams();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onChange: UploadProps<File>['onChange'] = async ({ file: { originFileObj } }) => {
    try {
      setIsUploading(true);
      const file = originFileObj as RcFile;
      const signedUrl = await getSignedUrl(sessionId, file);

      const response = await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          const progressPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progressPercent);
        },
      });

      if (response.status !== 200) {
        throw new Error('Upload failed');
      }

      await postNewUploadMessage(sessionId, file);
      
      // invalidate query after 1.5 second to give time for the message to be processed
      setTimeout(() => {
        queryClient.invalidateQueries('assetsProcessingStatus');
      }, 1500);

    } catch (error) {
      setError('Upload failed');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box mt={4}>
      {
        error && <Alert message={error} type="error" />
      }
      {!isUploading && (
        <Upload.Dragger onChange={onChange} multiple={true} accept=".pdf,.jpg,.jpeg,.png">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {/* Click or drag file to this area to upload */}
            Sleep je bestand, of klik hier om te uploaden
          </p>
        </Upload.Dragger>
      )}

      {isUploading && <Progress percent={uploadProgress} status="active" />}
    </Box>
  );
};

export default UploadFile;
