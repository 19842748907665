import { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Line } from 'rc-progress';
import FileSaver from 'file-saver';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import ZoomInOutlined from '@ant-design/icons/ZoomInOutlined';

import FilePreview from 'components/FilePreview/FilePreview';
import Box from 'components/Box/Box';
import ToolsBar from 'components/Tools/ToolsBar';

import { createPreviewPdf, flipPreviewPDF } from 'utils/pdf';
import { SContainer, SFilename } from './PdfEditor.styles';
import { downloadFile } from 'services/downloadService';
import { imagePlaces, scalingMethods } from 'utils/constants';
import {
  DeleteOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
} from '@ant-design/icons';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    border: 'none',
  },
};

const iconStyle = {
  fontSize: 20,
  cursor: 'pointer',
  alignSelf: 'end',
  marginLeft: 5,
};

const PdfEditor = ({
  file,
  filename,
  previewImage,
  previewImageHash,
  width,
  height,
  loading,
  config,
  onChange,
  onDelete,
  actions,
  percentage,
}) => {
  const [rotation, setRotation] = useState(actions?.rotation || 0);
  const [flip, setFlip] = useState(actions?.flip || false);
  const [visible, setVisible] = useState(false);
  const [originalPdfFile, setOriginalPdfFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [formValue, setFormValue] = useState({
    place: actions?.place,
    type: actions?.type,
  });

  const handleChange = (prop, value) => {
    setFormValue((prev) => ({ ...prev, [prop]: value }));
  };

  const closeModal = useCallback(() => {
    setVisible(false);
  }, []);

  const handlePreview = useCallback((e) => {
    e.stopPropagation();
    setVisible(true);
  }, []);

  const generate = useCallback(
    async (options, pdf, noEmit = false) => {
      const newRotaion = options.rotation || rotation;
      const file = await createPreviewPdf(pdf || originalPdfFile, {
        ...options,
        ...config,
        ...formValue,
        rotation: newRotaion,
        flip,
      });
      setPdfFile(file);
      if (!noEmit) {
        onChange(
          {
            flip,
            rotation: newRotaion,
            ...formValue,
          },
          file,
        );
      }
    },
    [originalPdfFile, flip, rotation, formValue, config, onChange],
  );

  const handleRotate = async (direction) => {
    let degree = direction === 'right' ? 90 : -90;
    degree = rotation + degree;
    await generate(
      {
        ...formValue,
        ...config,
        dimensions: { width, height },
        rotation: degree,
      },
      originalPdfFile,
    );
    setRotation(degree);
  };

  const handleFlip = useCallback(async () => {
    const file = await flipPreviewPDF(pdfFile);
    const isFlip = !flip;
    setPdfFile(file);
    setFlip(isFlip);
    onChange(
      {
        ...formValue,
        rotation,
        flip: isFlip,
      },
      file,
    );
  }, [pdfFile, flip, rotation, formValue]);

  useEffect(() => {
    (async () => {
      let pdfFile;
      if (typeof file === 'string') {
        pdfFile = new File([await downloadFile(file)], filename);
      } else {
        pdfFile = file;
      }
      setOriginalPdfFile(pdfFile);
      setPdfFile(pdfFile);
      generate({ dimensions: { height, width } }, pdfFile);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (originalPdfFile) {
        generate({ dimensions: { height, width } }, originalPdfFile);
      }
    })();
  }, [formValue]);

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
        <FilePreview
          image={previewImage}
          loading={loading}
          version={previewImageHash}
          onClick={handlePreview}
        />
      </Box>
      <Box mt="4px">
        <SFilename>{filename}</SFilename>
      </Box>
      {!loading && (
        <Box display="flex" justifyContent="space-between" marginTop="1rem">
          <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer' }} onClick={onDelete} />
          <Box display="flex" justifyContent={'space-between'} alignItems="center">
            <RotateRightOutlined style={iconStyle} onClick={() => handleRotate('right')} />
            <RotateLeftOutlined style={iconStyle} onClick={() => handleRotate('left')} />
            <SwapOutlined style={iconStyle} onClick={handleFlip} />
          </Box>
          <ZoomInOutlined style={iconStyle} onClick={handlePreview} />
        </Box>
      )}
      <Box mt={4}>
        {percentage > 0 && (
          <>
            Uploading {percentage} %
            <Line percent={percentage} strokeWidth="1" />
          </>
        )}
      </Box>

      <Modal
        isOpen={visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="File Editor"
      >
        <SContainer>
          <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
            <FilePreview file={pdfFile} editable={!originalPdfFile} large />
          </Box>
          <Box textAlign="left" p={3} pb={9} width={300} minWidth={300} background="#ffffff">
            <Box textAlign="right">
              <CloseOutlined style={{ cursor: 'pointer' }} onClick={closeModal} />
            </Box>
            <ToolsBar
              formValue={formValue}
              imagePlaces={imagePlaces}
              scalingMethods={scalingMethods}
              onChange={handleChange}
              onRotateRight={() => handleRotate('right')}
              onRotateLeft={() => handleRotate('left')}
              onFlip={handleFlip}
              onDownload={() => FileSaver.saveAs(new Blob([pdfFile]), 'result.pdf')}
            />
          </Box>
        </SContainer>
      </Modal>
    </>
  );
};

PdfEditor.propTypes = {};

export default PdfEditor;
