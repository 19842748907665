import { useRef, useState, useCallback, useMemo } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Document, Page } from 'react-pdf';

const padding = 50;

const FilePreview = ({
  file,
  image = '',
  loading,
  version = Date.now(),
  large = false,
  onClick,
}) => {
  const source = useMemo(
    () => (image.startsWith('data:image') ? image : `${image}?${version}`),
    [image, version],
  );
  const [width, setWidth] = useState(0);
  const ref = useRef();

  const handleRenderSuccess = useCallback((page) => {
    let [, , canvasWidth, canvasHeight] = page.view;
    canvasWidth = Math.abs(canvasWidth);
    canvasHeight = Math.abs(canvasHeight);
    const scale = canvasWidth / canvasHeight;
    const drawWidth = window.innerWidth - 300 - padding;
    const drawHeight = window.innerHeight - padding;

    let width = drawWidth;
    let height = width / scale;

    if (width > drawWidth || height > drawHeight) {
      height = drawHeight;
      width = height * scale;
    }

    setWidth(width);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
      ref={ref}
      onClick={onClick}
    >
      {loading ? (
        <ClipLoader color="#35b3ee" />
      ) : image ? (
        <img src={source} alt="preview" width="100%"  style={{
          maxHeight: '160px',
        }} />
      ) : (
        <Document file={file} loading={<ClipLoader color="#35b3ee" />}>
          <Page pageNumber={1} onRenderSuccess={handleRenderSuccess} width={width} />
        </Document>
      )}
    </div>
  );
};

export default FilePreview;
