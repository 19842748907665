import * as R from 'ramda';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Card, Col, Row, Spin, Button } from 'antd';
import { useQuery } from 'react-query';

import Assets from 'components/Assets';
import ProductCard from 'components/ProductCard/ProductCard';
import Box from 'components/Box/Box';
import API from 'utils/API';
import useQueryParams from 'hooks/query-params';
import useFetchConfig from 'hooks/fetch-config';
import { convertPdfToImage, getWidthForPreviewImage } from 'services/fileService';
import { SContainer, SLoaderContainer, SSticky } from './Uploader.styles';
import { createEmptyProduct } from 'utils/misc';

const getColsBySidesCount = (sidesCount) => {
  if (sidesCount === 1) {
    return 8;
  }
  if (sidesCount === 2) {
    return 12;
  }
  return 24;
}

const Uploader = () => {
  const { sessionId } = useQueryParams();
  const [products, setProducts] = useState([]);
  const [savingProducts, setSavingProducts] = useState(false);
  const [shouldHighlighDropArea, setShouldHighlighDropArea] = useState(false);
  const { config, fetch, loading, error } = useFetchConfig({
    onSuccess: useCallback(async () => {
      const data = await API.getStoredData(sessionId);
      const fetchedProducts = R.propOr([], 'products')(data);
      setProducts(fetchedProducts);
    }, [sessionId]),
  });

  const { data: assets } = useQuery('assets', API.getAssets);

  const totalNo = useMemo(() => products.reduce((acc, { count }) => acc + count, 0), [products]);

  const handleChange = (config = {}, productIdx, sideIdx) => {
    setProducts((prev) =>
      prev.map((product, index) =>
        productIdx === index
          ? {
              ...product,
              sides: (product.sides || []).map((side, ind) =>
                sideIdx === ind ? { ...side, ...config } : side,
              ),
            }
          : product,
      ),
    );
  };

  const handleImagePreviewChange = async (file, productIdx, sideIdx) => {
    if (!file) {
      return;
    }
    const sideId = products[productIdx]['sides'][sideIdx].id;
    const image = await convertPdfToImage(file, {
      quality: 0.5,
      width: getWidthForPreviewImage(file),
    });
    const previewImage = await API.updatePreviewImage(image, sideId);
    handleChange(
      {
        previewImage,
        previewImageHash: Date.now(),
      },
      productIdx,
      sideIdx,
    );
  };

  const handleDeleteSideAsset = useCallback((productIndex, sideIndex) => {
    setProducts((prev) => {
      const sides = prev[productIndex].sides;
      const side = prev[productIndex].sides[sideIndex];
      const updatedSide = R.omit(['previewImage', 'previewImageHash'], {
        ...side,
        asset: undefined,
        assetId: undefined,
      });
      const updateSides = R.update(sideIndex, updatedSide, sides);
      return R.update(productIndex, { ...prev[productIndex], sides: updateSides }, prev);
    });
  }, []);

  const handleIncrement = useCallback(
    (targetIdx) => {
      if (totalNo >= config?.quantity) {
        return;
      }
      setProducts((prev) =>
        prev.map((doc, index) => (targetIdx === index ? { ...doc, count: doc.count + 1 } : doc)),
      );
    },
    [totalNo, config?.quantity],
  );

  const handleDecrement = useCallback((targetIdx) => {
    setProducts((prev) =>
      prev.map((doc, index) =>
        targetIdx === index && doc.count > 0 ? { ...doc, count: doc.count - 1 } : doc,
      ),
    );
  }, []);

  const handleDeleteProduct = useCallback((productIdx) => {
    setProducts((prev) => prev.filter((_, index) => index !== productIdx));
  }, []);

  const onDragStart = (event, assetId) => {
    event.dataTransfer.setData('assetId', assetId);
    setShouldHighlighDropArea(true);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setShouldHighlighDropArea(false);
  };

  const onDrop = function (event, { productIndex, sideIndex }) {
    event.preventDefault();
    const assetId = event.dataTransfer.getData('assetId');
    handleSelectAssetForSide({ productIndex, sideIndex, assetId });
    setShouldHighlighDropArea(false);
  };

  const handleAddProduct = useCallback(() => {
    const sidesCount = config?.sidesCount;
    const product = createEmptyProduct(null, sidesCount);
    setProducts((products) => [...products, product]);
  }, [config]);

  const handleSelectAssetForSide = ({ assetId, productIndex, sideIndex }) => {
    if (!assetId) {
      throw new Error('invalid asset');
    }
    const side = products[productIndex]['sides'][sideIndex];
    const asset = assets.find(({ id }) => id === assetId);
    const newSide = {
      ...side,
      assetId,
      asset,
    };
    setProducts((products) =>
      products.map((p, index) => {
        if (index !== productIndex) {
          return p;
        }
        return {
          ...p,
          sides: p.sides.map((side, idx) => (idx === sideIndex ? newSide : side)),
        };
      }),
    );
  };

  const shouldDisplayUpload = useMemo(() => {
    return totalNo < config?.quantity ? true : false;
  }, [config?.quantity, totalNo]);

  useEffect(() => {
    fetch(sessionId);
  }, [sessionId, fetch]);

  useEffect(() => {
    if (!Object.keys(config).length) {
      return;
    }
    setSavingProducts(true);
    const productsToSend = products.map(({ sides, ...rest }) => ({
      ...rest,
      sides: sides.map(({ file, asset, ...rest }) => ({ ...rest })),
    }));
    API.updateProducts(productsToSend).finally(() => setSavingProducts(false));
  }, [products, config]);

  return (
    <>
      <SContainer>
        {loading ? (
          'Loading'
        ) : (
          <>
            {error ? (
              <h3>{error}</h3>
            ) : (
              <Box>
                <SSticky>
                  <h3>
                    Oplage toegewezen {totalNo} / {config?.quantity}
                  </h3>
                </SSticky>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Assets onDragStart={onDragStart} />
                  </Col>
                  <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 18 }}>
                    <Card style={{ overflow: 'auto', maxHeight: '100vh' }}>
                      {shouldDisplayUpload && (
                        <Button
                          type="primary"
                          size="middle"
                          style={{ marginBottom: '1rem' }}
                          onClick={handleAddProduct}
                          icon={<PlusOutlined />}
                        >
                          Voeg product toe
                        </Button>
                      )}
                      <Row className="gutter-row" gutter={[16, 16]}>
                        {products.map((product, index) => {
                          return (
                            <Col
                              className="gutter-row"
                              xs={{ span: 24 }}
                              md={{ span: getColsBySidesCount(config?.sidesCount)}}
                              key={index}
                            >
                              <ProductCard
                                key={product.id}
                                number={index + 1}
                                productIndex={index}
                                product={product}
                                config={config}
                                count={product.count}
                                onDragOver={onDragOver}
                                onDrop={onDrop}
                                onChange={({ file, actions }, sideIndex) => {
                                  handleImagePreviewChange(file, index, sideIndex);
                                  handleChange({ actions }, index, sideIndex);
                                }}
                                onIncrement={() => handleIncrement(index)}
                                onDecrement={() => handleDecrement(index)}
                                onDeleteProduct={() => handleDeleteProduct(index)}
                                onDeleteSide={(sideIndex) =>
                                  handleDeleteSideAsset(index, sideIndex)
                                }
                                shouldHighlighDropArea={shouldHighlighDropArea}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Box>
            )}
          </>
        )}
      </SContainer>
      {savingProducts && (
        <SLoaderContainer>
          <Spin size="large" />
        </SLoaderContainer>
      )}
    </>
  );
};

Uploader.propTypes = {};

export default Uploader;
