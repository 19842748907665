import * as dateFns from 'date-fns';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_PRINT_API_URL;

const API = {
  fetchConfig: async (sessionId) => {
    const res = await axios.get(`${BASE_URL}/${sessionId}/config`);
    const config = res?.data?.config || null;
    if (!config) {
      throw new Error('Invalid or expired session');
    }
    sessionStorage.setItem('config', JSON.stringify(config));
    sessionStorage.setItem('sessionId', sessionId);
    return config;
  },
  getStoredData: (sessionId) => {
    return axios.get(`${BASE_URL}/${sessionId}/data`).then(({ data }) => {
      return data;
    });
  },
  updateProducts: (products) => {
    const sessionId = API.getSessionId();
    return axios
      .post(`${BASE_URL}/${sessionId}/data/products`, products)
      .then(({ data }) => {
        return data;
      });
  },
  getAssets: () => {
    const sessionId = API.getSessionId();
    return axios
      .get(`${BASE_URL}/${sessionId}/data/assets`)
      .then(({ data: assets }) => {
        return assets.sort((assetA, assetB) => {
          return dateFns.compareDesc(
            dateFns.parse(
              assetA.createdAt,
              'yyyyMMdd:HHmmssSSS',
              new Date(),
            ),
            dateFns.parse(
              assetB.createdAt,
              'yyyyMMdd:HHmmssSSS',
              new Date(),
            ),
          );
        });
      });
  },
  getAssetsProcessingStatus: () => {
    const sessionId = API.getSessionId();
    return axios
      .get(`${BASE_URL}/${sessionId}/data/assets/status`)
      .then(({ data }) => {
        return data;
      });
  },
  deleteAsset: (assetId) => {
    const sessionId = API.getSessionId();
    return axios
      .delete(`${BASE_URL}/${sessionId}/data/assets/${assetId}`)
      .then(({ data }) => {
        return data;
      });
  },
  updatePreviewImage: (file, key) => {
    const sessionId = API.getSessionId();
    return axios
      .post(`${BASE_URL}/${sessionId}/previews`, {
        file,
        key,
      })
      .then(({ data: previewUrl }) => previewUrl);
  },
  getConfig: () => JSON.parse(sessionStorage.getItem('config') || null),
  getSessionId: () => sessionStorage.getItem('sessionId'),
};

export default API;
